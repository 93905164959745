import React, { useEffect, useState } from 'react';
import { Table, Button, Form } from 'react-bootstrap';

import Collector from './index';
import MembershipSelect from '../MembershipPopup';
import { getErpMembershipContent } from '../../utils/api';

export default ({
  setSubmit,
  hasView,
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setChosen,
  chosenMembership,
  setPromoCode,
  matchedCode,
  promoCode,
}) => {
  const [erpMembershipContent, setErpMembershipContent] = useState([]);
  const [selectedPlan, _setSelectedPlan] = useState({});

  const fetchErpMembershipContent = async () => {
    const res = await getErpMembershipContent();
    setErpMembershipContent(res ? res.filter((it) => it.active) : []);
  };

  useEffect(() => {
    fetchErpMembershipContent();
  }, []);

  useEffect(() => {
    setSubmit(true);
    return () => {
      setSubmit(false);
    };
  }, [hasView]);

  const [membershipStatus, setMembership] = useState(
    'Select a membership tier'
  );

  const [shouldShow, setShouldOpen] = useState(false);

  return (
    <>
      <Collector title='Review Your Details'>
        <Table>
          <tbody>
            <tr>
              <td>Email</td>
              <td>{values.email}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{values.firstName}</td>
            </tr>
            <tr>
              <td>Phone Number</td>
              <td>{values.phone}</td>
            </tr>
            <tr>
              <td>Website</td>
              <td>{values.website}</td>
            </tr>
            <tr>
              <td>Street Address</td>
              <td>{values.street}</td>
            </tr>
            <tr>
              <td>Suite or Apartment</td>
              <td>{values.apartment}</td>
            </tr>
            <tr>
              <td>City</td>
              <td>{values.city}</td>
            </tr>
            <tr>
              <td>Zipcode</td>
              <td>{values.zipcode}</td>
            </tr>
            <tr>
              <td>State</td>
              <td>{values.state}</td>
            </tr>
            <tr>
              <td>Membership Status</td>
              <td>
                <Button
                  variant='outline-primary'
                  onClick={() => setShouldOpen(true)}
                >
                  {membershipStatus}
                </Button>
                {errors && errors.card_token && (
                  <Form.Control.Feedback
                    type='invalid'
                    style={{ display: 'block' }}
                  >
                    Please provide a valid credit card
                  </Form.Control.Feedback>
                )}
                {errors && errors.membership && errors.membership.id && (
                  <Form.Control.Feedback
                    type='invalid'
                    style={{ display: 'block' }}
                  >
                    Please select a membership tier
                  </Form.Control.Feedback>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <Form.Group controlId='formBasicChecbox'>
          <Form.Check
            type='checkbox'
            className='text-primary'
            label={
              <>
                I have read and agree to the{' '}
                <a
                  target='__blank'
                  href='/terms-of-service'
                  className='font-weight-bold'
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  target='__blank'
                  href='/privacy-policy'
                  className='font-weight-bold'
                >
                  Privacy Policy
                </a>
              </>
            }
            isValid={!errors || !errors.didLegal}
            isInvalid={errors && errors.didLegal}
            name='didLegal'
            checked={values.didLegal}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Group>
        {errors && errors.didLegal && (
          <Form.Control.Feedback type='invalid' style={{ display: 'block' }}>
            You must agree to our Terms of Service and Privacy Policy
          </Form.Control.Feedback>
        )}
      </Collector>
      <MembershipSelect
        show={shouldShow}
        selectedPlan={selectedPlan}
        set_SelectedPlan={_setSelectedPlan}
        showCard={
          chosenMembership
            ? (
              chosenMembership.nickname
                ? chosenMembership.nickname === 'Standard'
                : chosenMembership.metadata.nickname === 'Standard'
            )
              ? false
              : true
            : true
        }
        handleClose={() => setShouldOpen(false)}
        plan={values.membership || {}}
        erpMembershipContent={erpMembershipContent && erpMembershipContent}
        submitMembership={(tok, membership) => {
          setFieldValue(
            'card_token',
            chosenMembership
              ? (
                chosenMembership.nickname
                  ? chosenMembership.nickname === 'Standard'
                  : chosenMembership.metadata.nickname === 'Standard'
              )
                ? null
                : tok
              : tok
          );
          setMembership(membership.metadata.nickname);
          setFieldValue('membership', membership);
          setShouldOpen(false);
        }}
        setChosen={setChosen}
        chosenMembership={chosenMembership}
        editPlan={null}
        setPromoCode={setPromoCode}
        promoCode={promoCode}
        matchedCode={matchedCode}
        review={true}
      />
    </>
  );
};
