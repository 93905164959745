import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

const Index = ({ title, children }) => (
  <Container>
    <Row>
      <Col className='step-title mb-4 mb-md-0' md={6}>
        <h2>{title}</h2>
      </Col>
      <Col>{children}</Col>
    </Row>
  </Container>
);

export default Index;
