import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Form, Button, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';

import SEO from '../components/Seo';
import Layout from '../components/layout';
import Details from '../components/signup/Details';
import Shipping from '../components/signup/Address';
import CreateAccount from '../components/signup/Review';
import PrivateRoute from '../components/auth/protected';
import CreateSchema from '../utils/schemas/userSchema';
import { createUser } from '../state/login/thunks';
import { querystring } from './login';

import 'react-toastify/dist/ReactToastify.css';
import { getPromotionCodes } from '../utils/api';

const Steps = [Details, Shipping, CreateAccount];

const schemas = [CreateSchema.user, CreateSchema.full, CreateSchema.withCard];
const schemasNoCard = [
  CreateSchema.user,
  CreateSchema.full,
  CreateSchema.noCard,
];

const getComponent = (num) => Steps[num];

const CreatePage = (props) => {
  const [isSubmiting, setSubmit] = useState(false);
  const [step, setStep] = useState(0);
  const [userObject, setUserObject] = useState({ ...props.profile });
  const [showButtons, setShouldShow] = useState(true);
  const componentRef = useRef();
  const [chosenMembership, setChosen] = useState(null);

  const [promoCodes, setPromoCodes] = useState([]);
  const [promoCode, setPromoCode] = useState(null);

  function matchPromoCode(givenCode, codes) {
    return codes.find((cd) => cd.code === givenCode.trim());
  }

  const fetchPromoCodes = async () => {
    const res = await getPromotionCodes();
    setPromoCodes(res.data);
  };

  useEffect(() => {
    if (promoCodes.length === 0) {
      fetchPromoCodes();
    }
  }, []);

  const matchedCode =
    promoCode &&
    matchPromoCode(
      promoCode,
      promoCodes.filter((cd) => cd.coupon.name.includes('Subscription'))
    );

  const submit = async (user, matchedCode) => {
    setSubmit(true);
    try {
      await props.createUser({ ...user, matchedCode: matchedCode });
    } catch (e) {
      setSubmit(false);
      const data = await e.response.json();
      if (data.error && data.error.rawType === 'card_error') {
        toast.error(
          'There was an error processing your card. Please re-enter your card information.'
        );
      } else {
        toast.error(
          'There was a problem creating your user, please try again later.'
        );
      }
      throw e;
    }
  };

  const changeValue = (fieldName, newValue) => {
    const newUser = { ...userObject };
    newUser[fieldName] = newValue;
    setUserObject(newUser);
  };

  const changeAddressValue = (fieldName, value) => {
    const newUser = { ...userObject };
    newUser.address[fieldName] = value;
    setUserObject(newUser);
  };

  const Component = getComponent(step);

  const action = showButtons
    ? () => setStep(step + 1)
    : async (user) => {
      await submit(user, matchedCode);
      const after = querystring('after')[0] || '/onboarding';
      navigate(props.isFirstTimeLogin ? '/video' : after);
    };

  const title = showButtons
    ? 'Next'
    : step === 2
      ? 'Continue to onboarding'
      : 'Finish';

  const schema = chosenMembership
    ? (
      chosenMembership.nickname
        ? chosenMembership.nickname === 'Standard'
        : chosenMembership.metadata.nickname === 'Standard'
    )
      ? schemasNoCard[step]
      : schemas[step]
    : schemas[step];

  return (
    <PrivateRoute
      forceCreate={false}
      validate={(userID, tok, getUser) => {
        getUser(userID)
          .then(() => {
            navigate('/');
          })
          .catch((e) => false);
      }}
    >
      <Layout onCreate={true}>
        <SEO title='Create your user' />
        <Row className='text-center'>
          <Col className='page-title mx-4 mx-md-12 text-center text-md-left' style={{ marginTop: '4rem' }}>
            <h1>Finish setting up your account</h1>
          </Col>
        </Row>
        <Formik
          initialValues={userObject}
          validationSchema={schema}
          onSubmit={(values) => {
            let newUserObject = { ...userObject, ...values };
            switch (step) {
              case 0: {
                newUserObject = { ...userObject, ...values };
                break;
              }
              case 1: {
                newUserObject = { ...userObject, ...values };
                break;
              }
              default: {
                newUserObject = { ...userObject, ...values };
                break;
              }
            }
            action(newUserObject);
            setUserObject(newUserObject);
          }}
          render={(props) => (
            <Form
              onSubmit={props.handleSubmit}
              validated={props.isValid}
              className='profile-form'
            >
              <Component
                {...props}
                forwardedRef={componentRef}
                profile={userObject}
                {...{ changeValue, changeAddressValue }}
                goToStep={setStep}
                setSubmit={(isSubmit) => setShouldShow(!isSubmit)}
                chosenMembership={chosenMembership}
                setChosen={setChosen}
                setPromoCode={setPromoCode}
                matchedCode={matchedCode}
                promoCode={promoCode}
              />
              <Row className='create-form-controls'>
                <Col>
                  <ButtonToolbar>
                    {step > 0 && (
                      <Button onClick={() => setStep(step - 1)}>Back</Button>
                    )}
                  </ButtonToolbar>
                </Col>
                <Col>
                  <Button type='submit' disabled={isSubmiting}>
                    {isSubmiting ? (
                      <>
                        <i className='fas fa-circle-notch fa-spin' /> Submitting
                      </>
                    ) : (
                      title
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </Layout>
    </PrivateRoute>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  isFirstTimeLogin:
    state.login &&
    state.login.signUpWorkflow &&
    state.login.signUpWorkflow.isFirstTimeLogin,
  profile: state.login.auth && state.login.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (user) => dispatch(createUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePage);
