import React from 'react';
import { Form } from 'react-bootstrap';

import Collector from './index';

export default ({ values, errors, handleBlur, handleChange, touched }) => (
  <Collector title='Your information'>
    <Form.Group controlId='formName'>
      <Form.Label>First Name</Form.Label>
      <Form.Control
        type='text'
        name='firstName'
        placeholder='Enter first name'
        value={values.firstName}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={!!errors.firstName}
        isValid={!!!errors.firstName}
        autoComplete={'none'}
      />
      <Form.Control.Feedback type='invalid'>
        {errors.firstName}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId='formName'>
      <Form.Label>Last name</Form.Label>
      <Form.Control
        type='text'
        name='lastName'
        placeholder='Enter name'
        value={values.lastName}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={!!errors.lastName}
        isValid={!!!errors.lastName}
        autoComplete={'none'}
      />
      <Form.Control.Feedback type='invalid'>
        {errors.lastName}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId='formBasicEmail'>
      <Form.Label>Email address</Form.Label>
      <Form.Control
        type='email'
        name='email'
        placeholder='Enter email'
        value={values.email}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={!!errors.email}
        isValid={!!!errors.email}
        autoComplete={'none'}
      />
      <Form.Control.Feedback type='invalid'>
        {errors.email}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId='formPhoneNumber'>
      <Form.Label> Phone Number</Form.Label>
      <Form.Control
        type='text'
        name='phone'
        placeholder='Enter Phone Number'
        value={values.phone}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={!!errors.phone}
        isValid={!!!errors.phone && touched.phone}
      />
      <Form.Control.Feedback type='invalid'>
        {errors.phone}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId='formWebSite'>
      <Form.Label>Website</Form.Label>
      <Form.Control
        type='text'
        name='website'
        placeholder='Enter website'
        value={values.website}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={!!errors.website}
        isValid={!!!errors.website && touched.website}
      />
      <Form.Control.Feedback type='invalid'>
        {errors.website} (Are you forgetting http:// or https://?)
      </Form.Control.Feedback>
    </Form.Group>
  </Collector>
);
