import React from 'react';
import { Form } from 'react-bootstrap';

import Collector from './index';
import CountryDropdown from '../CountryDropdown';
import StateDropdown from '../StateDropdown';

export default ({ values, errors, handleBlur, handleChange }) => (
  <Collector title='Your address'>
    <Form.Group controlId='formBasicEmail'>
      <Form.Label>Country</Form.Label>
      <CountryDropdown
        type='text'
        name='country'
        value={values.country}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={!!errors.country}
        isValid={!!!errors.country}
      />
      <Form.Control.Feedback type='invalid'>
        {errors.country}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId='formWebSite'>
      <Form.Label>State</Form.Label>
      <StateDropdown
        id={values.country}
        name='state'
        type='text'
        value={values.state}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={!!errors.state}
        isValid={!!!errors.state}
      />
      <Form.Control.Feedback type='invalid'>
        {errors.state}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId='formBasicEmail'>
      <Form.Label>Street Address</Form.Label>
      <Form.Control
        type='text'
        name='street'
        placeholder='Street Address'
        value={values.street}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={!!errors.street}
        isValid={!!!errors.street}
      />
      <Form.Control.Feedback type='invalid'>
        {errors.street}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId='formName'>
      <Form.Label>Suite or Apartment number</Form.Label>
      <Form.Control
        type='text'
        name='apartment'
        placeholder='suite or apartment number'
        value={values.apartment}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={!!errors.apartment}
        isValid={!!!errors.apartment}
      />
      <Form.Control.Feedback type='invalid'>
        {errors.apartment}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId='formCity'>
      <Form.Label>City</Form.Label>
      <Form.Control
        type='text'
        name='city'
        placeholder='City'
        value={values.city}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={!!errors.city}
        isValid={!!!errors.city}
      />
      <Form.Control.Feedback type='invalid'>
        {errors.city}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId='formZip'>
      <Form.Label>Zipcode</Form.Label>
      <Form.Control
        type='text'
        name='zipcode'
        placeholder='zipcode'
        value={values.zipcode}
        onBlur={handleBlur}
        onChange={handleChange}
        isInvalid={!!errors.zipcode}
        isValid={!!!errors.zipcode}
      />
      <Form.Control.Feedback type='invalid'>
        {errors.zipcode}
      </Form.Control.Feedback>
    </Form.Group>
  </Collector>
);
